.ps {
    display: flex;
    flex-direction: row;
    margin: 20px 5vw;
}

.ps-content {
    background-color: rgba(214, 214, 214, 0.39);
    backdrop-filter: blur(20px);
    padding: 20px 0;
    margin: 20px;
    border-radius: 60% 70% 50% 60%;
    transition: all 0.4s ease;
    position: relative;
    z-index: 2;
    width: 40vw;
    height: auto;
    margin-left: 5vw;
}

.ps-content:hover {
    background-color: rgba(245, 245, 245, 0.39);
    border-radius: 50% 40% 69% 30%;
    backdrop-filter: blur(25px);
}

.ps-items {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;

}

.ps-items ol li {
    font-size: 1.1rem;
    margin: 20px 0;
    text-align: left;
    cursor: psinter;
    font-weight: 600;
    padding: 10px 10px;
    border-radius: 50% 40% 55% 46%;
    transition: all 0.2s ease;
}

.ps-items ol li:hover {
    border-radius: 20% 10% 15% 6%;
    background-color: rgb(255, 220, 44);
}


.ps-ref {
    position: relative;
    z-index: 1;
    width: 20vw;
    transform: translateX(-50%);
}

.ps-ref img {
    width: 60vw;
    height: 60vh;
    object-fit: contain;
}


@media screen and (max-width: 800px) {
    .ps-ref{
        display: none;
    }
    .ps-content {
        font-size:0.8rem;
        padding: 20px 0;
        width: 100vw;
        border-radius: 10% 10% 15% 10%;
    }
}