.wca {
    display: flex;
    margin: 20px 5vw;
    flex-direction: row;
}

.wca-content {
    flex: 1;
    background-color: rgba(214, 214, 214, 0.39);
    backdrop-filter: blur(20px);
    border-radius: 75% 60% 70% 50%;
    padding: 10vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    transform: translateX(10%);
    transition: all 0.2s ease;
}

.wca-content:hover {
    border-radius: 80% 50% 60% 40%;
    backdrop-filter: blur(25px);
    background-color: rgba(221, 221, 221, 0.188);
}

.wca-content ul {
    width: 70%;
    text-align: left;
}

.wca-content button {
    border: none;
    background-color: rgba(255, 15, 15, 0.844);
    color: white;
    padding: 20px 40px;
    border-radius: 40px;
    -webkit-box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    -moz-box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    transition: all 0.2s ease;
    font-weight: 600;
    cursor: pointer
}

.wca-content button:hover {
    background-color: rgba(253, 217, 15, 0.895);
    color: black;
    -webkit-box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
    -moz-box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
    box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
}

.wca-img {
    flex: 1;
    position: relative;
    z-index: 1;
    transform: translateX(-10%);
}

.wca-img img {
    width: 100%;
}


@media screen and (max-width: 800px) {
    .wca-img {
        display: none;
    }

    .wca-content {
        border-radius: 15% 10% 10% 10%;
        padding: 10vh 0;
        transform: none;
        font-size: 0.8rem;
    }

}