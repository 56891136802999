.partners{
    display: flex;
    margin: 20px 10vw;
    flex-direction: row;
    margin-top: 5vh;
}

.partners-content{
    flex: 1.5;
    background-color: rgba(214, 214, 214, 0.39);
    backdrop-filter: blur(20px);
    border-radius: 75% 60% 70% 50%;
    padding: 10vh 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    transform: translateX(10%);
    transition: all 0.2s ease;
}

.partners-content:hover{
    border-radius: 80% 50% 60% 40%;
    backdrop-filter: blur(25px);
    background-color: rgba(241, 241, 241, 0.249);
}

.partner-logo-sec{
    display: flex;
    flex-wrap: wrap;
}

.partner-logo-sec img{
    margin: 20px;
    flex: 1;
    min-width: 200px; 
}



.partners-img{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    transform: translateX(-10%);
}

.partners-img img{
    width: 150%;
    transform: translateX(-15%);
}

@media screen and (max-width: 800px) {
    .partners{
        font-size: 0.8rem;
    }
}