.tl{
    margin: 20px 10vw;
}

.tl-content {
    display: flex;
    flex-direction: row;
}

/* Timeline Styles */
.timeline {
    flex: 1;
    position: relative;
    padding-left: 4rem;
    margin: 0 0 0 30px;
    color: black;
    background-color: transparent;
    text-align: left;
}

.timeline::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: rgba(214, 214, 214, 0.39);
}

.timeline-container {
    position: relative;
    margin-bottom: 2.5rem;
}

.timeline-container .timeline-icon {
    position: absolute;
    left: -88px;
    top: 4px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    background: red;
}

.timeline-container .timeline-icon i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.timeline-container .timeline-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.timeline-container .timeline-body {
    background: rgba(214, 214, 214, 0.39);
    border-radius: 20px 60% 30% 40px;
    padding: 20px 20px 15px;
    box-shadow: yellow;
    font-size: 1.3rem;
    transition: all 0.2s ease;
}

.timeline-body:hover{
    font-weight: 800;
    background: rgba(231, 231, 231, 0.23);
    border-radius: 20px 30% 60% 40px;
}



.timeline-container .timeline-body::before {
    content: '';
    background: inherit;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: -10px;
    transform: rotate(45deg);
    border-radius: 0 0 0 2px;
}

.timeline-container .timeline-body .timeline-title {
    margin-bottom: 1.4rem;
}

.timeline-container .timeline-body .timeline-title .badge {
    background: yellow;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 1.3rem;
    font-weight: bold;
}

.timeline-container .timeline-body .timeline-subtitle {
    font-weight: 300;
    font-style: italic;
    opacity: 0.4;
    margin-top: 16px;
    font-size: 11px;
}

/* Author Styles */
.author {
    font-family: inherit;
    padding: 3em;
    text-align: center;
    width: 100%;
    color: black;
}

.author a:link,
.author a:visited {
    color: white;
    text-decoration: none;
}

.author a:hover {
    text-decoration: none;
}

.author .btn:link,
.author .btn:visited {
    margin-top: 1em;
    text-decoration: none;
    display: inline-block;
    font-family: inherit;
    font-weight: 100;
    color: black;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    padding: 1.5em 2rem;
}

.timeline-icon .tl-icon {
    color: white;
}

.tl-img{
    flex:1;
}

.tl-img img{
    width: 100%;
    filter: brightness(120%);
    opacity: 0.8;
}

@media screen and (max-width: 800px) {
    .tl{
        font-size: 0.8rem;
    }
    .tl-img{
        display: none;
    }
}