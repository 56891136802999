.about {
    display: flex;
    flex-direction: row;
    min-height: 80vh;
    margin: 0 5vw;
}

.about-img-sec {
    flex: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(20%);
    position: relative;
    z-index: 1;
}

.about-content-sec {
    display: flex;
    flex: 1.5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.about-inner-sec {
    background-color: rgba(214, 214, 214, 0.39);
    backdrop-filter: blur(20px);
    padding: 80px 80px;
    margin-right: 10px;
    border-radius: 20% 40% 40% 40%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
}

.about-inner-sec:hover {
    background-color: rgba(245, 245, 245, 0.39);
    border-radius: 40% 60% 25% 40%;
    backdrop-filter: blur(25px);
}

.about-inner-sec p {
    width: 40vw;
    font-size: 1.2rem;
    text-align: justify;
}

.about-img-sec img {
    width: 100%;
}


@media screen and (max-width: 800px) {
    .about-inner-sec {
        margin-top: 50px;
        font-size: 0.8rem;
        text-align: justify;
        border-radius: 20px;
        padding: 10px;
    }

    .about-img-sec{
        display: none;
    }


    .about-inner-sec p {
        width: 100%;
    }
}