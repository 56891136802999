.po {
    display: flex;
    flex-direction: row;
    margin: 20px 5vw;
}

.po-content {
    flex: 1;
    padding: 20px 5vw;
    margin: 20px;
    border-radius: 60% 70% 50% 60%;
    background-color: rgba(214, 214, 214, 0.39);
    backdrop-filter: blur(20px);
    transition: all 0.4s ease;
    position: relative;
    z-index: 1;
}

.po-content:hover {
    border-radius: 60% 40% 65% 20%;
    background-color: rgba(232, 232, 232, 0.278);
    backdrop-filter: blur(25px);
}

.po-items {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
}

.po-items ol li {
    font-size: 1.1rem;
    margin: 20px 0;
    text-align: left;
    cursor: pointer;
    font-weight: 600;
    padding: 10px 10px;
    border-radius: 50% 40% 55% 46%;
    transition: all 0.2s ease;
    border-radius: 10px;
    background-color: white;
    transition: all 0.2s ease;
}

.po-items ol li:hover {
    border-radius: 10px;
    background-color: rgb(249, 41, 0);
    color: white;
    scale: 102%;
}


.po-ref {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -1;
    transform: translateX(20%);
}

.po-ref img {
    width: 40vw !important;
    object-fit: cover;
    height: auto;
    border-radius: 30px;
}

@media screen and (max-width: 800px) {
    .po-content {
        font-size: 0.8rem;
        border-radius: 10% 10% 10% 10%;
        background-color: rgba(214, 214, 214, 0.39);
        transition: all 0.4s ease;
        position: relative;
        z-index: 1;
    }
    .po-ref{
        display: none;
    }
}