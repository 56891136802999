.footer {
    border-radius: 100% 90% 0 0;
    background-color: rgba(214, 214, 214, 0.39);
    padding: 8vh 0;
    transition: all 0.4s ease;
    margin-top: 5vw;
}

.footer:hover {
    border-radius: 30% 60% 0 0;
    background-color: rgba(233, 233, 233, 0.39);
}

.footer-content {
    margin: 0 10vw;
    display: flex;
}

.footer-content .footer-left-content {
    flex: 1;
    align-items: center;
    justify-content: center;
}

.footer-left-content p {
    margin-left: 8vw;
    text-align: left;
}

.footer-mid-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-mid-content ul{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-inline-start: 0px;
}

.footer-mid-content li{
    margin: 10px;
    text-decoration: none;
}

.footer-mid-content a{
    width: 40px;
    text-decoration: none;
    
}

.footer-content .footer-right-content {
    flex: 1;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.footer-right-content button {
    border: none;
    background-color: rgba(255, 15, 15, 0.844);
    color: white;
    padding: 20px 40px;
    border-radius: 40px;
    -webkit-box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    -moz-box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    transition: all 0.2s ease;
    font-weight: 600;
    cursor:pointer;
}

.footer-right-content button:hover{
    background-color: rgba(253, 217, 15, 0.895);
    color: black;
    -webkit-box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
    -moz-box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
    box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
}

.icon-obj{
    text-decoration: none;
    font-size: 2rem;
    color: black;
}

.icon-obj:hover{
    text-decoration: none;
    font-size: 2rem;
    color: red;
}

@media screen and (max-width: 800px) {
    .footer-content{
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
    }
}


