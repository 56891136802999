.header {
    height: 10vh;
    width: calc(100vw - 400px);
    padding: 0 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    position: relative;
    margin: 10px 0;
}

.header img{
    width: 200px;
}



.header .apply-section button {
    border: none;
    background-color: rgba(255, 15, 15, 0.844);
    color: white;
    padding: 20px 40px;
    border-radius: 40px;
    -webkit-box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    -moz-box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    box-shadow: -30px 16px 57px 4px rgba(253, 217, 15, 0.895);
    transition: all 0.2s ease;
    font-weight: 600;
    font-size: 1.2rem;
    cursor:pointer;
}

.header .apply-section button:hover{
    background-color: rgba(253, 217, 15, 0.895);
    color: black;
    -webkit-box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
    -moz-box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
    box-shadow: -30px 16px 57px 4px rgba(255, 15, 15, 0.844);
}

@media screen and (max-width: 800px) {
    .header{
        padding: 20px;
        width: 90vw;
    }

    .header img{
        width: 100px;
    }
    .header .apply-section button{
        padding: 10px 20px;
    } 
}