.hero {
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: rgb(253, 253, 253);
    position: relative;
    overflow-y: hidden;
}


.hero .hero-img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
}

.hero-image-sec {
    position: absolute;
    top: 10vh;
    left: 15vw;
    background-color: rgba(214, 214, 214, 0.39);
    backdrop-filter: blur(5px);
    z-index: 2;
    border-radius: 20% 40% 45% 60%;
    transition: all 0.4s ease;
}

.hero-image-sec:hover {
    background-color: rgba(245, 245, 245, 0.39);
    border-radius: 40% 60% 25% 40%;
}

.hero-image-sec img {
    width: 35vw;
    height: auto;
}

@media screen and (max-width: 800px) {
    .hero .hero-img {
        height: 30vh;
        object-fit: contain;
    }

    .hero-image-sec {
        top: 0.5vh;
        left: 12vw;
    }

    .hero-image-sec img {
        width: 50vw;

    }
}